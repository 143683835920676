<script setup lang="ts">
const props = defineProps({
  category: String,
  slug: String,
});

const categoryMap = [
  {
    slug: "pregnancy",
    title: "Pregnancy",
  },
  {
    slug: "parenting",
    title: "Parenting",
  },
  {
    slug: "motherhood",
    title: "Motherhood",
  },
  {
    slug: "lifestyle",
    title: "Lifestyle",
  },
  {
    slug: "reviews",
    title: "Reviews",
  },
  {
    slug: "days-out",
    title: "Days Out",
  },
];

const category = categoryMap.find((cat) => cat.slug === props.slug);

const posts = await queryContent("posts")
  .where({ categories: { $contains: category?.title ?? "" } })
  .limit(4)
  .sort({ publishedAt: -1 })
  .find();

const firstPost = computed(() => posts[0]);
const remainingPosts = computed(() => posts.slice(1));
const hasPosts = computed(() => posts.length > 3);
</script>

<template>
  <section v-if="hasPosts" class="py-8">
    <div class="container">
      <NuxtLink :to="`/${props.slug}`">
        <h2
          class="font-heading font-black text-3xl my-4 hover:underline underline-offset-4"
        >
          {{ props.category }}
        </h2>
      </NuxtLink>

      <div
        class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 md:gap-8"
      >
        <NuxtLink
          v-if="firstPost"
          :to="`/${props.slug}/${firstPost?.slug}`"
          class="col-span-1 md:col-span-1 lg:col-span-1"
          :key="firstPost._path"
        >
          <figure class="aspect-4/3 bg-gray-200 rounded-md overflow-hidden">
            <NuxtPicture
              :src="firstPost.cover"
              :alt="firstPost.title"
              :title="firstPost.title"
              class="object-cover w-full h-full"
              format="webp"
              sizes="280px sm:608px md:336px lg:216px xl:280px"
              loading="lazy"
              :img-attrs="{
                loading: 'lazy',
                class: 'w-full object-cover aspect-square',
              }"
            />
          </figure>
          <h3
            class="text-pretty text-2xl lg:text-lg font-bold font-heading my-2 hover:underline hover:underline-offset-2"
          >
            {{ firstPost.title }}
          </h3>
        </NuxtLink>

        <div
          class="col-span-1 lg:col-span-3 lg:gap-8 space-y-4 lg:space-y-0 lg:grid lg:grid-cols-3"
        >
          <div v-for="post in remainingPosts" :key="post._path">
            <NuxtLink
              :to="`/${props.slug}/${post.slug}`"
              class="flex lg:block lg:col-span-1"
            >
              <figure
                class="aspect-4/3 bg-gray-200 rounded-md overflow-hidden mr-4 lg:mr-0"
              >
                <NuxtPicture
                  :src="post.cover"
                  :alt="post.title"
                  :title="post.title"
                  class="object-cover flex-0 w-[120px] h-auto lg:w-full lg:mr-0 aspect-4/3 bg-gray-200 rounded-md"
                  format="webp"
                  sizes="120px lg:216px xl:280px"
                  loading="lazy"
                  :img-attrs="{
                    loading: 'lazy',
                    class: 'w-full object-cover aspect-square',
                  }"
                />
              </figure>
              <h3
                class="text-pretty flex-1 text-base lg:text-lg font-heading font-bold my-2 hover:underline hover:underline-offset-2"
              >
                {{ post.title }}
              </h3>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
